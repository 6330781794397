<template>
  <StandardModal v-if="plan" name="proSubscribeModal" :min-width="minWidth" class="sb-subscribe-modal">
    <template #-slot:content>
      <div class="sb-subscribe-modal-content">
        <div v-if="step !== STEPS.LOGIN" class="sb-subscribe-modal-content-logo">
          <img
            :src="
              step === STEPS.CONFIRMATION && !selectedPlan.auto_subscription
                ? '/illustrations/picto_validation.png'
                : '/logos/logo-stockpro-gradient.png'
            "
          />
        </div>
        <div v-show="[STEPS.OFFER_SELECTION, STEPS.PAYMENT].includes(step)" class="sb-subscribe-modal-content-solution">
          <div class="sb-subscribe-modal-content-solution-title">{{ $t('proArea.offers.offerSubscription') }}</div>
          <div
            v-if="step === STEPS.OFFER_SELECTION && (!selectedPlan || !selectedPlan.title)"
            class="sb-subscribe-modal-content-solution-subtitle"
          >
            {{ $t('proArea.offers.howManyEmployees') }}
          </div>
          <div class="sb-subscribe-modal-content-solution-name">
            {{ selectedPlan.title }}
          </div>
          <div v-if="step === STEPS.OFFER_SELECTION" class="sb-subscribe-modal-content-solution-subtitle">
            {{ selectedPlan.subtitle }}
          </div>
          <div
            v-if="plans && plans.length && step === STEPS.OFFER_SELECTION"
            class="sb-subscribe-modal-content-solution-employees"
          >
            <div class="sb-subscribe-modal-content-solution-employees-salary">
              <div v-for="(employee, idx) in plans" :key="idx">
                <div
                  v-if="employee"
                  class="sb-subscribe-modal-content-solution-employees-salary-item"
                  :is-select="employee.employees === selectedPlan.employees"
                  @click="selectPlan(employee)"
                >
                  {{ employee.employees }}
                </div>
              </div>
              <div>{{ $t('proArea.employees') }}</div>
            </div>
          </div>
          <SbOfferDetail
            v-if="selectedPlan && selectedPlan.price"
            :plan="selectedPlan"
            :show-vat="step === STEPS.PAYMENT"
            :class="step === STEPS.PAYMENT ? 'u-m-t-md' : ''"
          />
          <div v-show="step === STEPS.PAYMENT" class="sb-subscribe-modal-content-solution-payment">
            <div class="stripe-input">
              <!-- Stripe will insert element here -->
              <div ref="CARD" />
            </div>
            <div
              v-show="richPaymentInfoRender"
              class="sb-subscribe-modal-content-solution-payment-info"
              v-html="richPaymentInfoRender"
            />
          </div>
          <div v-if="selectionError && step === STEPS.OFFER_SELECTION && !selectedPlan.price" class="u-c-grapefruit">
            {{ $t('proArea.offers.selectionError') }}
          </div>
          <div v-if="stripeError" class="u-c-grapefruit u-font-bold u-text-sm">
            {{ stripeError.message || stripeError }}
          </div>
        </div>
        <div
          v-show="step === STEPS.CONFIRMATION && !selectedPlan.auto_subscription"
          class="sb-subscribe-modal-content-confirmation"
        >
          <div class="sb-subscribe-modal-content-confirmation-title">
            {{ $t('proArea.confirmation.title') }}
          </div>
          <div class="sb-subscribe-modal-content-confirmation-subtitle" v-html="$t('proArea.confirmation.subtitle')" />
        </div>
        <div
          v-show="step === STEPS.CONFIRMATION && selectedPlan.auto_subscription"
          class="sb-subscribe-modal-content-confirmation"
        >
          <div class="sb-subscribe-modal-content-confirmation-title">
            {{ $t('proArea.confirmation.title_auto') }}
          </div>
          <div
            class="sb-subscribe-modal-content-confirmation-subtitle"
            v-html="$t('proArea.confirmation.subtitle_auto')"
          />
          <SbOfferDetail :plan="selectedPlan" :show-vat="true" />
        </div>
        <div v-show="step === STEPS.LOGIN" class="sb-subscribe-modal-content-login">
          <login-form :compact="true" @go-back="step = STEPS.PAYMENT" @proceed-to-payment="pay" />
        </div>
      </div>
    </template>
    <template #options>
      <div v-if="step !== STEPS.LOGIN" class="sb-subscribe-modal-footer">
        <div class="sb-subscribe-modal-footer-logo hide-xs">
          <img
            src="~/assets/logos/logo-stockpro-color.svg"
            :alt="$t('header.logoAlt')"
            :title="$t('header.logoAlt')"
            class-variant="logo"
          />
        </div>
        <div class="sb-subscribe-modal-footer-buttons">
          <main-button
            :label="step === STEPS.CONFIRMATION ? $t('actions.closeButton') : $t('actions.cancel')"
            tag="a"
            :class-variant="{ 'btn-grey': step !== STEPS.CONFIRMATION }"
            @click="close"
          />
          <main-button
            v-if="[STEPS.OFFER_SELECTION, STEPS.PAYMENT].includes(step)"
            :loading="isLoading"
            :disabled="step === STEPS.PAYMENT ? !(isStripeLoaded && isStripeReady) : false && isLoading"
            :label="step === STEPS.PAYMENT ? $t('actions.subscribe') : $t('actions.continue')"
            tag="a"
            @click="nextStep"
          />
        </div>
      </div>
    </template>
  </StandardModal>
</template>

<script>
import { nextTick } from 'vue'
import API from '@/mixins/sp-api-mixin'
import Stripe from '@/mixins/stripe-mixin'
import Mobile from '@/mixins/mobile-mixin'
import i18nMx from '@/mixins/i18n-mixin'

import StandardModal from '@/components/Modals/StandardModal/StandardModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import LoginForm from '@/components/Login/LoginForm/LoginForm'
import SbOfferDetail from '@/components/Storyblok/SbOfferDetail'

const STEPS = Object.freeze({
  OFFER_SELECTION: 'OFFER_SELECTION',
  PAYMENT: 'PAYMENT',
  LOGIN: 'LOGIN',
  CONFIRMATION: 'CONFIRMATION',
})

export default {
  name: 'SbSubscribeModal',
  components: {
    StandardModal,
    MainButton,
    LoginForm,
    SbOfferDetail,
  },
  mixins: [Stripe, Mobile, API, i18nMx],
  props: {
    plans: { type: Array, default: () => [] },
    plan: { type: Object, default: () => {} },
  },
  emits: ['modalOpen_proSubscribeModal', 'modalClose_proSubscribeModal'],
  data() {
    return {
      step: STEPS.OFFER_SELECTION,
      STEPS,
      selectedPlan: {},
      currentPayment: null,
      selectionError: false,
      stripeError: null,
    }
  },
  computed: {
    richPaymentInfoRender() {
      if (this.selectedPlan?.payment_info) {
        return renderRichText(this.selectedPlan?.payment_info)
      }
      return ''
    },
    minWidth() {
      return this.isSm ? '95%' : this.step == STEPS.LOGIN ? '420px' : '570px'
    },
  },
  mounted() {
    this.$bus.emit('modalOpen_proSubscribeModal', 'proSubscribeModal')
    this.initStripe(false)
  },
  methods: {
    close() {
      const localePath = useLocalePath()
      this.$router.replace(localePath(this.$route.path))
      this.step = STEPS.OFFER_SELECTION
      this.$bus.emit('modalClose_proSubscribeModal', 'proSubscribeModal')
    },
    nextStep() {
      const { $currentUser } = useNuxtApp()
      const { analytics, events } = useAnalytics()
      if (this.step === STEPS.OFFER_SELECTION) {
        if (!this.selectedPlan || !this.selectedPlan.price) {
          return (this.selectionError = true)
        }
        this.selectionError = false
        this.step = STEPS.PAYMENT
        nextTick(() => {
          this.initCardPayment()
          analytics.sendEvent(events.BEGIN_CHECKOUT, {
            ecommerce: {
              currency: 'EUR',
              value: Number(this.selectedPlan.price_vat),
              items: [
                {
                  item_id: this.selectedPlan.key,
                  item_name: this.selectedPlan.name,
                  affiliation: null,
                  coupon: null,
                  name: this.selectedPlan.name,
                  quantity: 1,
                  price: Number(this.selectedPlan.price_vat),
                  discount: 0,
                  currency: 'EUR',
                  item_category4: 'subscription',
                },
              ],
            },
          })
        })
      } else if (this.step === 'PAYMENT') {
        if ($currentUser().isAuthenticated) {
          this.pay()
        } else {
          this.step = STEPS.LOGIN
        }
      }
    },
    async createSubscriptionIntent(company, appPurchase) {
      const { $api } = useNuxtApp()
      const result = await this.spRequest({
        req: $api().company.subscribeStripe(company.uid, { app_purchase_id: appPurchase.uid, recurrence: 'yearly' }),
      })
      return result
    },
    async pay() {
      const { country } = useCurrentLocale()
      this.step = STEPS.PAYMENT
      this.stripeError = null
      this.isLoading = true
      try {
        const { data } = await this.createSubscriptionIntent($currentUser().company, this.selectedPlan)
        if (!data) {
          this.isLoading = false
          return (this.stripeError = this.$t('backendError.unknown', { phone: country?.phoneNumber }))
        }
        this.isLoading = true
        await this.stripe
          .confirmCardPayment(data.client_secret, {
            payment_method: {
              card: this.card,
            },
          })
          .then(result => {
            if (result.error) {
              this.stripeError = result.error
            } else {
              this.step = STEPS.CONFIRMATION
              const { analytics, events } = useAnalytics()
              analytics.sendEvent(events.PURCHASE, {
                ecommerce: {
                  transaction_id: data.subscription_id,
                  value: Number(this.selectedPlan.price_vat),
                  tax: Number(this.selectedPlan.price_vat) - Number(this.selectedPlan.price),
                  currency: 'EUR',
                  payment_type: 'CB',
                  payment_plan: 'yearly',
                  items: [
                    {
                      item_id: this.selectedPlan.key,
                      item_name: this.selectedPlan.name,
                      name: this.selectedPlan.name,
                      quantity: 1,
                      price: Number(this.selectedPlan.price_vat),
                      discount: 0,
                      currency: 'EUR',
                      item_category4: 'subscription',
                    },
                  ],
                },
              })
              this.isLoading = false
            }
          })
          .catch(e => {
            return this.$te(`backendError.${e}`)
              ? (this.stripeError = this.$t(`backendError.${e}`, { phone: country?.phoneNumber }))
              : (this.stripeError = this.$t('backendError.unknown', { phone: country?.phoneNumber }))
          })
      } catch (e) {
        return this.$te(`backendError.${e}`)
          ? (this.stripeError = this.$t(`backendError.${e}`, { phone: country?.phoneNumber }))
          : (this.stripeError = this.$t('backendError.unknown', country?.phoneNumber))
      }
    },
    selectPlan(plan = this.selectedPlan) {
      this.selectedPlan = plan
    },
    async initStripe() {
      const config = useRuntimeConfig()
      this.stripe = await loadStripe(config.public.STRIPE_API_KEY)
      this.isStripeLoaded = true
    },
  },
}
</script>

<style lang="scss">
.sb-subscribe-modal {
  .modal-container {
    max-height: 80vh;
    overflow: auto;
  }
  .modal-body {
    margin-top: 0 !important;
  }
  &-content {
    display: flex;
    gap: $spacing-md;
    color: var(--dark-blue);

    @include mq($mq-xs) {
      flex-direction: column;
    }

    &-logo img {
      width: 150px;
      height: 150px;
      @include mq($mq-xs) {
        width: 50px;
        height: 50px;
      }
    }

    &-solution,
    &-confirmation {
      &-title,
      &-name {
        font-size: pxToRem(20px);
        font-weight: 800;
      }
      &-subtitle {
        padding-top: $spacing-sm;
        font-size: pxToRem(15px);
      }
      &-name {
        color: var(--primary);
      }
      &-employees {
        &-salary {
          display: flex;
          padding-top: $spacing-sm;
          padding-bottom: $spacing-md;
          gap: $spacing-sm;
          align-items: center;

          &-item {
            font-weight: 700;
            width: 38px;
            height: 30px;
            padding: $spacing-xs $spacing-sm;
            background-color: var(--bg-grey);
            border-radius: 5px;
            cursor: pointer;

            &[is-select='true'] {
              background-color: var(--primary);
              color: var(--white);
            }
          }
        }
      }
      &-payment {
        .stripe-input {
          background-color: var(--bg-grey);
          padding-left: $spacing-sm;
        }
        &-help {
          margin: $spacing-sm 0;
        }
        &-info {
          p,
          a {
            font-size: pxToRem(12px);
            margin-bottom: $spacing-sm;
            margin-top: $spacing-md;
            text-decoration: none;
          }
        }
      }
    }
    &-confirmation {
      &-title {
        font-size: pxToRem(20px);
        font-weight: 800;
      }
      &-subtitle {
        font-size: pxToRem(15px);
        margin-bottom: $spacing-md;
      }
    }
  }
  &-footer {
    align-items: flex-end;
    display: flex;
    flex: 1;
    justify-content: space-between;

    @include mq($mq-xs) {
      flex-direction: column-reverse;
    }

    &-logo {
      filter: grayscale(100%);
      img {
        width: 110px;
      }
    }
    &-buttons {
      display: flex;
      gap: $spacing-sm;
      @include mq($mq-xs) {
        padding-bottom: $spacing-sm;
        width: 100%;
        .btn {
          flex: 1;
        }
      }
    }
  }
}
</style>
